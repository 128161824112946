import React, { useEffect, useContext, memo } from 'react';
import { redirectWhenStatusPageIsNotValid, navigateToError } from '../../../utils/navigate';
import { CreditcardContext } from '../../../context/creditcard.context';
import { isGreaterThan, diffTwoDatesInSeconds as diff, isTrue, isUndefined } from '../../../utils/functions';
import { FormNewCard } from '../../../components/287634/form/new-card';
import { Loading } from '../../../components/287634/loading';
import Layout from '../../../components/287634/layout/layout';
import { H2 } from '../../../components/287634/typography';

import { delay } from '../../../utils/functions';


const RenderLayout = memo(
  ({ render, token, location, allValid}: any) => {
    if (!render) {
      const loading= allValid?<Loading text="Procesando recarga"/>:<Loading/>
      return loading
    }
    return (
      <Layout>
        <section className="mx-5 flex flex-auto flex-col text-primary">
          <H2 className="mt-[60px] mb-8 text-center">Realiza la recarga y agrega tu tarjeta</H2>
          <p className="text-base leading-tight mx-5 mb-10 text-center text-primary">Estás a un paso de poder enviar dinero a través de WhatsApp.<br/>
            Agrega y valida ahora tu tarjeta.<br/>
            Empezamos ingresando el importe que indicaste en el chat.</p>
          <FormNewCard token={token} location={location} payable />
        </section>
      </Layout>
    );
  },
  // need to check for `expirationTime` prop within `infoUser`
  // because shallow object comparison is not enough to trigger a re-render
  ({ render: prev, infoUser: prevInfoUser }, { render: next, infoUser: nextInfoUser }) => {
    return prev === next && prevInfoUser.expirationTime === nextInfoUser.expirationTime;
  }
);

const AddPage = ({ getToken, location = {} }: PageProps) => {
  const { infoUser, timeStamp, getCardPayment, loading } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();

  useEffect(() => {
    if ((!infoUser?.expirationTime || !infoUser?.tokenExID) && token) {
      getCardPayment?.(token);
    } else if (isUndefined(token)) {
      navigateToError()?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp);
    }
  }, [infoUser, timeStamp]);

  const dateDiff = diff(timeStamp ?? '', infoUser?.expirationTime ?? '');
  const render = [allValid, !!dateDiff, !infoUser.status, !loading].every(isTrue);
  return <RenderLayout render={render} token={token} location={location} infoUser={infoUser} allValid={allValid}/>;
};

export default AddPage;
