import React, { useState, useRef, useEffect, Fragment } from 'react';
import Portal from '../portal';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactElement;
};

const Modal = ({ open, onClose, children }: ModalProps) => {
  const container = useRef(null);
  const [modalState, setModalState] = useState<string>('idle');

  const transitionEnd = (event: TransitionEvent) => {
    if (modalState === 'closing' && event.propertyName === 'opacity') {
      setModalState('idle');
      onClose();
    }
  };

  useEffect(() => {
    const { current } = container;
    if (current) {
      current.addEventListener('transitionend', transitionEnd);
    }
    if (open) {
      document.body.classList.add('overflow-hidden');
      if (modalState === 'idle') {
        setModalState('opening');
      }
    }
    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
      }
      document.body.classList.remove('overflow-hidden');
    };
  }, [open, modalState]);

  return (
    <Fragment>
      {open && (
        <Portal>
          <div className="fixed inset-0 z-50 flex items-center justify-center xs:absolute">
            <div
              className={`absolute inset-0 bg-gray transition-opacity duration-200 ease-out ${
                modalState === 'opening' ? 'opacity-50' : 'opacity-0'
              }`}
            ></div>
            <div
              ref={container}
              className={`absolute left-5 right-5 mx-auto max-w-[25.5rem] rounded-[32px] bg-white px-5 py-12 transition-all duration-200 ease-out ${
                modalState === 'opening' ? 'scale-100 opacity-100' : 'scale-75 opacity-0'
              }`}
            >
              <button
                className="absolute right-4 top-4 h-7 w-7"
                type="button"
                onClick={() => {
                  setModalState('closing');
                }}
              >
                <img
                  alt="Close"
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjcuODAwOTMiIHkxPSI3Ljc1NTg2IiB4Mj0iMjAuNTI4OSIgeTI9IjIwLjQ4MzgiIHN0cm9rZT0iIzAwMDA0NiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPGxpbmUgeDE9IjIwLjUyOTMiIHkxPSI3LjgwMDkzIiB4Mj0iNy44MDEzNyIgeTI9IjIwLjUyODkiIHN0cm9rZT0iIzAwMDA0NiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg=="
                />
              </button>
              {children}
            </div>
          </div>
        </Portal>
      )}
    </Fragment>
  );
};

export default Modal;
