import React from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, parent }: { children: any; parent?: any }) => {
  const el = React.useMemo(() => document.createElement('div'), []);
  React.useEffect(() => {
    let target: any;
    if (parent && parent.appendChild) {
      target = parent;
    } else if (document.querySelector('#silbo-main')) {
      target = document.querySelector('#silbo-main');
    } else {
      target = document.body;
    }
    el.classList.add('portal-container');
    target.appendChild(el);
    return () => {
      target.removeChild(el);
    };
  }, [el, parent]);
  return ReactDOM.createPortal(children, el);
};

export default Portal;
